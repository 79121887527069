<template>
  <div>
    <Header />
    <div class="calendar-container">
      <FullCalendar :options="calendarOptions" ref="calendar" />
    </div>
    <div class="imp">
      <b-button variant="success" @click="changeView('week')">Weekly View</b-button>
      <b-button variant="info" @click="changeView('month')">Monthly View</b-button>
      <b-button variant="primary" @click="changeView('year')">Yearly View</b-button>

      <b-button @click="downloadPDF">Download PDF</b-button>
    </div>

    <!-- Tooltip Area -->
    <div v-if="tooltipVisible" class="event-tooltip" :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }">
      <h3>{{ selectedEvent.title }}</h3>
      <div v-html="selectedEvent.description"></div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import weekGridPlugin from '@fullcalendar/timegrid'

import 'fullcalendar/dist/fullcalendar.css'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'


export default {
  name: 'Oceancalender',
  components: {
    Header,
    FullCalendar
  },
  data() {
    return {
      selectedEvent: null, // To hold the currently selected event details
      tooltipVisible: false, // Control visibility of the tooltip
      tooltipX: 0,
      tooltipY: 0,
      calendarOptions: {
        plugins: [dayGridPlugin, weekGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        height: 'auto',
        contentHeight: 'auto',
        events: [],
        eventDidMount: this.customEventRender,
        views: {
          month: {
            type: 'dayGridMonth',
            duration: { months: 1 }
          },
          week: {
            type: 'dayGridWeek',
            duration: { week: 2 }
          },
          year: {
            type: 'dayGridYear',
            duration: { year: 1 }
          }
        },
        firstDay: 1,
        dateClick: this.handleDateClick,
      }
    }
  },
  created() {
    this.$xhr.get('oceancalender.json').then(response => {
      const shipSchedules = response.data.slice(1) // Ignore the first empty object
      const groupByDate = {}
      shipSchedules.forEach(schedule => {
        if (!schedule.Date) {
          return
        }
        const date = schedule.Date.substring(0, 10)
        const shipCode = `${schedule.Ship}_${schedule.Code}`
        if (!groupByDate[date]) {
          groupByDate[date] = {}
        }
        if (!groupByDate[date][shipCode]) {
          groupByDate[date][shipCode] = []
        }
        groupByDate[date][shipCode].push(schedule)
      })

      // Create events array with one event per unique ship+code per date
      const events = []
      Object.keys(groupByDate).forEach(date => {
        Object.keys(groupByDate[date]).forEach(shipCode => {
          const schedules = groupByDate[date][shipCode]
          const ship = schedules[0].Ship
          const port = schedules[0].Port
          const cruiseLine = schedules[0]['Cruise Line'];
          const start = schedules[0].Start;
          const end = schedules[0].End;
          const lp = schedules[0].LP;
          const title = `${ship} in ${port}`;
          const description = `Cruise Line: ${cruiseLine}<br>Start: ${start}<br>End: ${end}<br>LP: ${lp}`;

          // Determine the color based on the port
          let color = '';
          let textColor = '';
          switch (port) {
            case 'Kiel':
              color = 'darkorange';
              textColor = 'white';

              break;
            case 'Hamburg':
              color = 'purple';
              textColor = 'white';
              break;
            case 'Binz':
            case 'Wismar':
            case 'Sassnitz':
              color = 'black';
              textColor = 'white';
              break;
            case 'Rostock':
              color = 'darkred';
              break;
            case 'Travemunde':
            case 'Helgoland':
              color = 'green';
              textColor = 'white';
              break;
            case 'Warnemunde':
              color = '';
              textColor = 'white';
              break;

          }

          events.push({
            title,
            start: date,
            allDay: true,
            extendedProps: { description },
            color,
            textColor
          });
        });
      });
      this.calendarOptions.events = events;

      console.log('Events:', events); // Debugging
    });
  },
  methods: {
    // Show event details in the tooltip
    showTooltip(event, tooltip) {
      this.selectedEvent = {
        title: event.event.title,
        description: event.event.extendedProps.description
      };
      this.tooltipX = tooltip.clientX; // Get the X coordinate of the mouse
      this.tooltipY = tooltip.clientY; // Get the Y coordinate of the mouse
      this.tooltipVisible = true; // Show the tooltip
    },
    hideTooltip() {
      this.tooltipVisible = false; // Hide the tooltip
    },
    customEventRender(info) {
      // Add event listeners for showing tooltip on hover
      info.el.addEventListener('mouseenter', (event) => {
        this.showTooltip(info, event); // Show event details in the tooltip
      });
      info.el.addEventListener('mouseleave', () => {
        this.hideTooltip(); // Hide the tooltip
      });
    },
    changeView(view) {
      this.$refs.calendar.getApi().changeView(view);
    },
    downloadPDF(e) {
      e.preventDefault();
      const isBrowser = typeof window !== 'undefined';

      if (isBrowser) {
        const calendarEl = document.querySelector('.fc');
        const style = document.createElement('style');
        style.innerHTML = '.fc-title, .fc-description { font-size: 60px !important; }';
        calendarEl.appendChild(style);
      }

      html2canvas(document.querySelector('.fc'), {
        backgroundColor: '#ffffff',
        scale: 2
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'mm', [420, 297]); // Change width and height here

        pdf.addImage(imgData, 'JPEG', 0, 0, 420, 297); // Change width and height here
        pdf.save('calendar.pdf');

        if (isBrowser) {
          const calendarEl = document.querySelector('.fc');
          const style = calendarEl.querySelector('style');
          calendarEl.removeChild(style);
        }
      });
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
}

.fc-event-title {
  font-size: 16.7px;
}

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}

.calendar-container {
  margin-top: 122px;
}

.fc-h-event .fc-event-main {
  color: black;
}

.event-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 16px;
  border-radius: 4px;
  z-index: 1000;

}
</style>
