export function formatPrice(price) {
  if (price === null || price === undefined) return '';

  // Convert to number and format with German locale
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price) + ' €';
}


export function formatNumber(price) {
  if (price === null || price === undefined) return '';

  // Convert to number and format with German locale
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
}