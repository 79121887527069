<template>
  <div v-if="loading">Loading...</div>
  <div v-else>
    <div class="card mt-4">
      <div class="card-header">
        <h1 class="mb-0">{{ tour.attributes.tourName }}</h1>
        <router-link :to="{ path: '/calculation/tourlist' }">
          <b-button v-b-tooltip.hover title= "Back to Tour Table" variant="dark">Back to List</b-button>
        </router-link>
      </div>
      <div class="card-body">
        <div class="row">
          <div v-for="(label, key) in details" :key="key" class="col-sm-12 col-md-6 col-lg-3 col">
            <strong>{{ label }}</strong>
            <p>{{ tour.attributes[key] }}</p>
          </div>

          <!-- Cities -->
          <div class="col-sm-12 col-md-6 col-lg-3 col">
            <strong>Cities</strong>
            <p>
              <span v-for="city in tour.attributes.cities.data" :key="city.id">
                {{ city.attributes.city }}<br>
              </span>
            </p>
          </div>

          <!-- Port -->
          <div class="col-sm-12 col-md-6 col-lg-3 col">
            <strong>Port City</strong>
            <p>{{ tour.attributes.port?.data?.attributes?.city || 'N/A' }}</p>
          </div>

          <!-- Timing & Description -->
          <div class="col-sm-12 col-md-6 col-lg-6 col" v-for="(text, key) in texts" :key="key">
            <strong>{{ text }}</strong>
            <textarea class="form-control confined-textarea" v-model="tour.attributes[key]"
              @blur="saveTimingDescription(key)">
            </textarea>
          </div>
          <!-- Comments -->
          <div class="col-sm-12 col-md-6 col-lg-12 col">
            <strong>Comments</strong>
            <div class="position-relative">
              <textarea class="form-control" style="background-color:#d4edda; padding-right: 30px;"
                v-model="tour.attributes.comments" @blur="saveComments">
               </textarea>
              <i class="fas fa-check text-success"
                style="position: absolute; top: 10px; right: 10px; cursor: pointer;"></i>
            </div>
          </div>


          <!-- Remarks -->
          <div class="col-sm-12 col-md-6 col-lg-12 col" v-if="tour.attributes.remarks">
            <strong>Remarks</strong>
            <textarea class="form-control" style="background-color: #f8d7da;" v-model="tour.attributes.remarks"
              @input="updateRemarks">
            </textarea>
          </div>

        </div>
      </div>
    </div>

    <!-- Service Modal -->
    <b-modal ref="serviceModal" id="service-modal" title="Add New Service" @ok="addService">
      <div class="form-group">
        <label for="serviceType">Select Type</label>
        <b-select v-model="selectedType" id="serviceType" class="form-control">
          <option value="" disabled>Select a type</option>
          <option v-for="type in serviceTypes" :key="type"> {{ type }}</option>
        </b-select>
      </div>

      <div class="form-group" v-if="filteredServices.length">
        <label for="newService">Select Service</label>
        <b-select v-model="selectedService" id="newService" class="form-control">
          <option value="" disabled>Select a service</option>
          <option v-for="service in filteredServices" :key="service.id" :value="service.id">
            {{ service.attributes.item }}
          </option>
        </b-select>
      </div>
    </b-modal>

    <!-- Remove Confirmation Modal -->
    <b-modal ref="removeConfirmModal" id="remove-confirm-modal" title="Confirm Removal" @ok="confirmRemoveService">
      <p>Are you sure you want to remove this item from the tour?</p>
    </b-modal>

    <!-- Tables for Different Item Types -->
    <div class="card mt-4">
      <div class="card-header">
        <h6 class="mb-0">Items by Type</h6>
        <b-button variant="info" v-b-tooltip.hover title= "Add Services" @click="showModal">+</b-button>
      </div>

      <div class="card-body">
        <div v-if="filteredItems('per person').length">
          <h5>Per Person</h5>
          <table class="table table-striped custom-table">
            <thead class="thead-dark">
              <tr>
                <th>items</th>
                <th v-for="key in filteredItemKeys('per person')" :key="key">{{ key }}</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredItems('per person')" :key="item.id">
                <td>{{ item.attributes.item }}</td>
                <td v-for="key in filteredItemKeys('per person')" :key="key">
                  <span v-if="key === 'city'">
                    {{ item.attributes.city?.data?.attributes?.city || 'N/A' }}
                  </span>
                  <span v-else>
                    <span
                      v-if="['adultPrice_gross', 'adultPrice_net', 'childPrice_gross', 'childPrice_net', 'price'].includes(key)">
                      {{ formatPrice(item.attributes[key]) }}
                    </span>
                    <span v-else>
                      {{ item.attributes[key] }}
                    </span>
                  </span>
                </td>
                <td>
                  <b-button size="sm" class="remove-btn" v-b-tooltip.hover title= "Remove  Per Person Service From Tour" @click="showRemoveConfirm(item.id)">
                    <i class="fas fa-trash"></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Table for Bulk -->
        <div v-if="filteredItems('bulk').length">
          <h5>Bulk</h5>
          <table class="table table-striped custom-table">
            <thead class="thead-dark">
              <tr>
                <th>items</th>
                <th v-for="key in filteredItemKeys('bulk')" :key="key">{{ key }}</th>
                <th>factor</th>
                <th>calculated price</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredItems('bulk')" :key="item.id">
                <td>{{ item.attributes.item }}</td>
                <td v-for="key in filteredItemKeys('bulk')" :key="key">
                  <span v-if="key === 'city'">
                    {{ item.attributes.city?.data?.attributes?.city || 'N/A' }}
                  </span>
                  <span v-else>
                    <span
                      v-if="['adultPrice_gross', 'adultPrice_net', 'childPrice_gross', 'childPrice_net', 'price'].includes(key)">
                      {{ formatPrice(item.attributes[key]) }}
                    </span>
                    <span v-else>
                      {{ item.attributes[key] }}
                    </span>
                  </span>
                </td>
                <td>
                  <b-input type="number" step="0.01" v-model="itemFactors[item.id]"
                    @input="updateItemFactor(item.id, itemFactors[item.id])" />
                </td>
                <td>
                  <span v-if="item.attributes.price">
                    {{ formatPrice(item.attributes.price * itemFactors[item.id]) }}
                  </span>
                </td>
                <td>
                  <b-button size="sm" class="remove-btn" v-b-tooltip.hover title= "Remove Bulk Service From Tour" @click="showRemoveConfirm(item.id)">
                    <i class="fas fa-trash"></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <!-- Table for Hourly -->
        <div v-if="filteredItems('hourly').length">
          <h5>Hourly</h5>
          <table class="table table-striped custom-table">
            <thead class="thead-dark">
              <tr>
                <th>items</th>
                <th v-for="key in filteredItemKeys('hourly')" :key="key">{{ key }}</th>
                <th>factor</th>
                <th>calculated price</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredItems('hourly')" :key="item.id">
                <td>{{ item.attributes.item }}</td>
                <td v-for="key in filteredItemKeys('hourly')" :key="key">
                  <span v-if="key === 'city'">
                    {{ item.attributes.city?.data?.attributes?.city || 'N/A' }}
                  </span>
                  <span v-else>
                    <span
                      v-if="['adultPrice_gross', 'adultPrice_net', 'childPrice_gross', 'childPrice_net', 'price'].includes(key)">
                      {{ formatPrice(item.attributes[key]) }}
                    </span>
                    <span v-else>
                      {{ item.attributes[key] }}
                    </span>
                  </span>
                </td>
                <td>
                  <b-input type="number" step="0.01" v-model="itemFactors[item.id]"
                    @input="updateItemFactor(item.id, itemFactors[item.id])" />
                </td>
                <td>
                  <span v-if="item.attributes.price">
                    {{ formatPrice(item.attributes.price * itemFactors[item.id]) }}
                  </span>
                </td>
                <td>
                  <b-button size="sm" class="remove-btn" v-b-tooltip.hover title= "Remove Hourly Service From Tour" @click="showRemoveConfirm(item.id)">
                    <i class="fas fa-trash"></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>



        <!-- No Items Available Message -->
        <div
          v-if="!filteredItems('per person').length && !filteredItems('bulk').length && !filteredItems('hourly').length">
          <p>No items available for this tour.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { formatPrice } from '../Helper/PriceDisplay.js';

export default {
  data() {
    return {
      tour: {},
      loading: true,
      availableServices: [],
      selectedService: null,
      selectedType: null,
      serviceTypes: [],
      filteredServices: [],
      details: {
        tourName: 'Tour Name',
        tourCode: 'Tour Code',
        duration: 'Duration',
        paxMin: 'Min Pax',
        paxMax: 'Max Pax',
        cruiseLine: 'Cruise Line'
      },
      texts: {
        timing: 'Timings',
        description: 'Description'
      },
      itemKeys: [
        'type',
        'tax',
        'rate',
        'catagory',
        'remarks',
        'cancellation',
        'supplier',
        'adultPrice_gross',
        'adultPrice_net',
        'childPrice_gross',
        'childPrice_net',
        'price',
      ],
      serviceToRemove: null,
      itemFactors: {},
      remarks: ''
    };
  },
  async created() {
    const tourId = this.$route.params.id;

    // Fetch tour data
    const tourResponse = await this.$xhr.get(`/api/tours/${tourId}?populate[item][populate]=*&populate[cities][populate]=*&populate[port][populate]=*`);
    this.tour = tourResponse.data.data;
    this.itemFactors = this.tour.attributes.itemFactor || {}; // Initialize itemFactors

    // Fetch services
    const servicesResponse = await this.$xhr.get('/api/services?populate[cities][populate]=*');
    const tourYear = this.tour.attributes.year;
    const tourCities = this.tour.attributes.cities.data.map(city => city.attributes.city);
    this.availableServices = servicesResponse.data.data.filter(service => {
      const serviceYear = service.attributes.year;
      const serviceCities = service.attributes.cities?.data.map(city => city.attributes.city) || [];
      const isYearMatch = serviceYear === tourYear;
      const isCityMatch = serviceCities.some(city => tourCities.includes(city));
      return isYearMatch && isCityMatch;
    });

    // Populate service types
    this.serviceTypes = [...new Set(this.availableServices.map(service => service.attributes.type))];

    this.loading = false;
  },
  watch: {
    selectedType(newType) {
      this.filteredServices = this.availableServices
        .filter(service => service.attributes.type === newType)
        .sort((a, b) => a.attributes.item.localeCompare(b.attributes.item)); // Sorting alphabetically
      this.selectedService = null;
    }
  },
  methods: {
    formatPrice,
    filteredItemKeys(type) {
      if (type === 'per person') {
        return this.itemKeys.filter(key => !['price'].includes(key));
      } else {
        return this.itemKeys.filter(key => !['adultPrice_net', 'childPrice_net', 'adultPrice_gross', 'childPrice_gross'].includes(key));
      }
    },
 async addService() {
  if (this.selectedService) {
    const newService = await this.$xhr.get(`/api/services/${this.selectedService}?populate=*`).then(res => res.data.data);
    this.tour.attributes.item.data.push(newService);
    
    if (!newService.attributes.itemFactor) {
      this.$set(this.itemFactors, newService.id, 1);
    } else {
      this.$set(this.itemFactors, newService.id, newService.attributes.itemFactor); 
    }
    
    await this.$xhr.put(`/api/tours/${this.tour.id}`, {
      data: {
        item: this.tour.attributes.item.data.map(service => ({ id: service.id })),
        itemFactor: this.itemFactors 
      }
    });
    this.selectedService = null;
    this.selectedType = null;

    this.$bvToast.toast('Item added successfully!', {
      title: 'Success',
      variant: 'success',
      solid: true,
      autoHideDelay: 5000
    });
  }
},

    showRemoveConfirm(serviceId) {
      this.serviceToRemove = serviceId;
      this.$refs.removeConfirmModal.show();
    },
    async confirmRemoveService() {
      this.tour.attributes.item.data = this.tour.attributes.item.data.filter(item => item.id !== this.serviceToRemove);
      await this.$xhr.put(`/api/tours/${this.tour.id}`, {
        data: { item: this.tour.attributes.item.data.map(service => ({ id: service.id })) }
      });

      this.$bvToast.toast('Item removed successfully!', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000
      });

      this.serviceToRemove = null;  // Reset after removal
    },
    async updateItemFactor(serviceId, newValue) {
      // Update local state
      this.$set(this.itemFactors, serviceId, newValue);
      await this.$xhr.put(`/api/tours/${this.tour.id}`, {
        data: { itemFactor: this.itemFactors }
      });
      this.$bvToast.toast('Item factor updated successfully!', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000
      });
    },
    async updateRemarks() {
      // Update the remarks field in the backend
      await this.$xhr.put(`/api/tours/${this.tour.id}`, {
        data: { remarks: this.tour.attributes.remarks }
      });
      this.$bvToast.toast('Remarks updated successfully!', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000
      });
    },
    async saveComments() {
      try {
        await this.$xhr.put(`/api/tours/${this.tour.id}`, {
          data: {
            comments: this.tour.attributes.comments,
          },
        });
        this.$bvToast.toast('Comments saved successfully.', { variant: 'success' });
      } catch (error) {
        this.$bvToast.toast('Failed to save comments.', { variant: 'danger' });
      }
    },
    async saveTimingDescription(key) {
      try {
        await this.$xhr.put(`/api/tours/${this.tour.id}`, {
          data: {
            [key]: this.tour.attributes[key],
          },
        });
        this.$bvToast.toast(`${key} saved successfully.`, { variant: 'success' });
      } catch (error) {
        this.$bvToast.toast(`Failed to save ${key}.`, { variant: 'danger' });
      }
    },

    filteredItems(type) {
      return this.tour.attributes.item.data.filter(item => item.attributes.type === type);
    },
    showModal() {
      this.$refs.serviceModal.show();
    }
  }
};
</script>



<style scoped>
.remove-btn {
  background-color: red;
  color: white;
  margin-left: 1rem;
}

.confined-textarea {
  height: 20rem;
  text-align: justify
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h1,
.card-header h6 {
  flex: 1;
  text-align: center;
}
</style>