import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export async function generateExcel(selectedRows, year) {
  if (!selectedRows || selectedRows.length === 0) {
    console.error('No data to export');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Tours');

  // Define columns for the Excel sheet, including the new "Time Change Inclusion" column
  worksheet.columns = [
    { header: 'Cruise Line', key: 'cruiseLine', width: 20 },
    { header: 'Tour Code', key: 'tourCode', width: 12 },
    { header: 'Tour Name', key: 'tourName', width: 30 },
    { header: 'Min Pax', key: 'paxMin', width: 10 },
    { header: 'Max Pax', key: 'paxMax', width: 10 },
    { header: 'Tier', key: 'tier', width: 10 },
    { header: 'Adult Price', key: 'adultPrice', width: 15 },
    { header: 'Child Price', key: 'childPrice', width: 15 },
    { header: 'Description', key: 'description', width: 50 },
    { header: 'Time Change Inclusion', key: 'timeChangeInclusion', width: 30 }, // New column
    { header: 'Time Change Remarks', key: 'timeChangeRemarks', width: 30 },
  ];

  // Set styles for header row from column A to K
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };
  headerRow.alignment = { horizontal: 'center', vertical: 'middle' };

  const headerColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']; // Columns A to K
  headerColumns.forEach((col) => {
    worksheet.getCell(`${col}1`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E2923D' }, // Set the background color to #E2923D
    };
  });

  let currentRow = 2; // Start adding rows after the header

  // Add data rows with merging for each tour
  selectedRows.forEach((row) => {
    const tourDetails = {
      tourCode: row.attributes.tourCode,
      tourName: row.attributes.tourName,
      cruiseLine: row.attributes.cruiseLine,
      description: row.attributes.description || 'N/A',
      paxMin: row.attributes.paxMin || 'N/A',
      paxMax: row.attributes.paxMax || 'N/A',
    };

    const tierCount = row.attributes.tiers.length;

    // Add a row for each tier
    row.attributes.tiers.forEach((tier, index) => {
      const adultPrice = tier.userInputSellingPriceAdult ? tier.userInputSellingPriceAdult + ' €' : 'N/A';
      const childPrice = tier.userInputSellingPriceChild ? tier.userInputSellingPriceChild + ' €' : 'N/A';

      const newRow = worksheet.addRow({
        cruiseLine: index === 0 ? tourDetails.cruiseLine : '',
        tourCode: index === 0 ? tourDetails.tourCode : '',
        tourName: index === 0 ? tourDetails.tourName : '',
        paxMin: index === 0 ? tourDetails.paxMin : '',
        paxMax: index === 0 ? tourDetails.paxMax : '',
        tier: tier.value,
        adultPrice: adultPrice,
        childPrice: childPrice,
        description: index === 0 ? tourDetails.description : '', // Only show description on the first tier
        timeChangeInclusion: '',  // Empty column for Time Change Inclusion
        timeChangeRemarks: '',  // Empty column for Time Change Remarks
      });

      // Make tier and prices bold
      newRow.getCell('F').font = { bold: true }; // Tier
      newRow.getCell('G').font = { bold: true }; // Adult Price
      newRow.getCell('H').font = { bold: true }; // Child Price

      // Wrap text in the description field
      newRow.getCell('I').alignment = { wrapText: true, vertical: 'top' };

      // Set a fixed row height for all tiers
      newRow.height = 60; // Fixed row height of 60 for all rows

      currentRow++;
    });

    // Merge cells for tour details across the number of tiers
    if (tierCount > 1) {
      worksheet.mergeCells(`A${currentRow - tierCount}:A${currentRow - 1}`); // Cruise Line
      worksheet.mergeCells(`B${currentRow - tierCount}:B${currentRow - 1}`); // Tour Code
      worksheet.mergeCells(`C${currentRow - tierCount}:C${currentRow - 1}`); // Tour Name
      worksheet.mergeCells(`D${currentRow - tierCount}:D${currentRow - 1}`); // Min Pax
      worksheet.mergeCells(`E${currentRow - tierCount}:E${currentRow - 1}`); // Max Pax
      worksheet.mergeCells(`I${currentRow - tierCount}:I${currentRow - 1}`); // Description
      worksheet.mergeCells(`J${currentRow - tierCount}:J${currentRow - 1}`); // Time Change Inclusion
      worksheet.mergeCells(`K${currentRow - tierCount}:K${currentRow - 1}`); // Time Change Remarks
    }
  });

  // Set row heights, apply borders, and center-align text for each row up to column K
  for (let i = 2; i <= worksheet.rowCount; i++) {
    const row = worksheet.getRow(i);
    row.alignment = { horizontal: 'center', vertical: 'middle' }; // Center align all text
    row.getCell('I').alignment = { wrapText: true, vertical: 'top' }; // Enable text wrapping for Description

    // Apply background color for Time Change Inclusion and Remarks columns (J, K)
    ['J', 'K'].forEach((col) => {
      worksheet.getCell(`${col}${i}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B7E1CD' }, // Light green color (60% lighter)
      };
    });

    // Apply borders to columns A through K only
    for (let j = 1; j <= 11; j++) { // Columns A to K are from index 1 to 11
      worksheet.getCell(`${String.fromCharCode(64 + j)}${i}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
  }

  // Generate the Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${selectedRows[0].attributes.year}_Cost_Sheet_${selectedRows[0].attributes.cruiseLine}.xlsx`);
}
