<template>
  <div class="navigation">
    <template v-if="profile && profile.role">
      <template v-if="profile.role.name === 'Berlin'">
        <router-link to="/oceancalender">Ocean Calender</router-link>
        <router-link to="/calculation/tourlist">Ocean Calculation</router-link>

        <a href="#" v-on:click="logout">Logout ({{profile.email}})</a>
      </template>
      <template v-else-if="profile.role.name === 'Cologne'">
        <router-link to="/calender">River Calender</router-link>
        <a href="#" v-on:click="logout">Logout ({{profile.email}})</a>

      </template>
      <template v-else-if="profile.role.name === 'Authenticated'">
        <router-link to="/ocean">Berlin Office</router-link>
        <router-link to="/uk">London Office</router-link>
        <router-link to="/oceancalender">Ocean Calender</router-link>
        <router-link to="/calculation/tourlist">Ocean Calculation</router-link>
        <router-link to="/calender">River Calender</router-link>
        <a href="#" v-on:click="logout">Logout ({{profile.email}})</a>
      </template>
      <template v-else>
        <b-link :to="{name: 'login'}">Please login</b-link>
      </template>
    </template>
  </div>
</template>

<script>
import { inject } from 'vue'
export default {
  name: "Header",
  setup(){
    const {profile, logout} = inject('user')
    return {
      profile,
      logout
    }
  },
};
</script>
  
<style>
.navigation {
  background-color: #333;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navigation a {
  float: left;
  color: aliceblue;
  padding: 14px 16px;
  text-align: center;
  font-size: 17px;
  text-decoration: none;
  margin-right: 5px;
}

.navigation a:hover {
  background: #ddd;
  color: #333;
}

/* Dropdown button */
.dropbtn {
  background-color: #333;
  color: aliceblue;
  padding: 14px 16px;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #ddd;
  color: #333;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #ddd;
  color: #333;
}

.ad {
  position: relative;
  margin-top: -3cm;
  margin-left: 45cm;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

.imp {
  position: fixed;
  right: 20px;
  margin-top: 0px;
  top: 10px;
  z-index: 9999;
}

.imp button {
  margin-right: 10px;

  /* add margin between buttons */
}

.head {
  margin-top: 85px;
}



.graph {
  margin-left: 10px;
}



table {
  font-size: 12px;
}


.d-flex {
  position: fixed;
  top: 53px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.clear-1 {
  position: fixed;
  left: 180px;
  top: 59px;
  cursor: pointer;
}

.clear-2 {
    position: fixed;
    left: 378px;
    top: 59px;
    cursor: pointer;
}

.clear-3 {
  position: fixed;
  left: 590px;
  top: 59px;
  cursor: pointer;
}

.clear-4 {
  position: fixed;
  left: 795px;
  top: 59px;
  cursor: pointer;
}

.clear-5 {
  position: fixed;
  left: 1010px;
  top: 59px;
  cursor: pointer;
}

.clear-6 {
  position: fixed;
  left: 1215px;
  top: 59px;
  cursor: pointer;
}

.clear-7 {
  position: fixed;
  left: 1425px;
  top: 59px;
  cursor: pointer;
}
</style>
  