<template>
  <div class="tabs">
    <b-tabs v-model="activeTab" @change="handleTabChange">
      <b-tab title="Tour List" @click="changeTab(0)">
        <router-view/>
      </b-tab>
      <b-tab title="Item List" @click="changeTab(1)">
        <router-view />
      </b-tab>
      <b-tab title="Audit-Logs" @click="changeTab(2)">
        <router-view />
      </b-tab>
      <b-tab title="Cities" @click="changeTab(3)">
        <router-view />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {

  setup() {
    const router = useRouter();
    const route = useRoute();
    const activeTab = ref(getInitialTabIndex());

    function getInitialTabIndex() {
      const tab = route.path.split('/').pop();
      return tab === 'tourlist' ? 0 : 
             tab === 'itemlist' ? 1 : 
             tab === 'audit' ? 2 : 
             tab === 'city' ? 3 : 0; 
    }

    function handleTabChange(tabIndex) {
      const tabName = tabIndex === 0 ? 'tourlist' : 
                      tabIndex === 1 ? 'itemlist' : 
                      tabIndex === 2 ? 'audit' : 
                      'city';
      router.push(`/calculation/${tabName}`);
    }

    function changeTab(tabIndex) {
      handleTabChange(tabIndex);
    }


    // Watch for route changes and update the active tab
    watch(route, () => {
      activeTab.value = getInitialTabIndex();
    });

    // Initialize tab index on mount
    onMounted(() => {
      activeTab.value = getInitialTabIndex();
    });

    return {
      activeTab,
      handleTabChange,
      changeTab
    };
  }
};
</script>

<style scoped>
.tabs {
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0; 
  margin-top: 2.5%; 
  z-index: 1000;
  background-color: white; 
  padding: 10px; 
}
body {
  overflow: hidden; /* Disable scrolling for the entire page */
  margin: 0; 
  padding: 0;
}
</style>
