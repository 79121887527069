import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export async function exportSelectedServices() {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Services');

  // Define columns
  worksheet.columns = [
    { header: 'Id', key: 'id', width: 10 },
    { header: 'Item', key: 'item', width: 30 },
    { header: 'Year', key: 'year', width: 10 },
    { header: 'City', key: 'city', width: 20 },
    { header: 'Adult Price (Gross)', key: 'adultPriceGross', width: 20 },
    { header: 'Child Price (Gross)', key: 'childPriceGross', width: 20 },
    { header: 'Adult Price (Net)', key: 'adultPriceNet', width: 20 },
    { header: 'Child Price (Net)', key: 'childPriceNet', width: 20 },
    { header: 'Price (Gross)', key: 'priceGross', width: 20 },
    { header: 'Type', key: 'type', width: 15 },
    { header: 'Tax', key: 'tax', width: 15 },
    { header: 'Tax Rate', key: 'taxRate', width: 15 },
    { header: 'Category', key: 'category', width: 20 },
    { header: 'Remarks', key: 'remarks', width: 30 },
    { header: 'Cancellation', key: 'cancellation', width: 30 },
    { header: 'Supplier', key: 'supplier', width: 20 },
  ];

  // Add rows for selected services
  const selectedData = this.services.filter(service => this.selectedServices.includes(service.id)).map(service => ({
    id: service.id,
    item: service.attributes.item,
    year: service.attributes.year,
    city: service.attributes.cities.data.map(city => city.attributes.city).join(', '),
    adultPriceGross: this.formatPrice(service.attributes.adultPrice_gross),
    childPriceGross: this.formatPrice(service.attributes.childPrice_gross),
    adultPriceNet: this.formatPrice(service.attributes.adultPrice_net),
    childPriceNet: this.formatPrice(service.attributes.childPrice_net),
    priceGross: this.formatPrice(service.attributes.price),
    type: service.attributes.type,
    tax: service.attributes.tax,
    taxRate: `${service.attributes.rate}%`,
    category: service.attributes.catagory,
    remarks: service.attributes.remarks,
    cancellation: service.attributes.cancellation,
    supplier: service.attributes.supplier,
  }));

  worksheet.addRows(selectedData);

  // Create buffer and save file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'selected_services.xlsx'); // Use saveAs instead of FileSaver.saveAs
  });

  this.hideContextMenu(); 
}
